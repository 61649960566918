/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes achats';
export const txtnombredoc = 'achat';
export const txtnombredocpluriel = 'achats';
export const titleAlveole = 'Mes achats';

// TODO arriver a faire fonctionner
export const alveoleLogoSvg = "./../images/icon-alv-mesconsos-blanc.svg";
/************************ DeV ******************************************************/
export const zAPIdocuments = "https://api-mesconsos-dev.cristal-hub.fr/consos";
export const zUrlBack = "https://api-mesconsos-dev.cristal-hub.fr";
export const zUrlBackNotifications = "https://api-servicenotification-dev.cristal-hub.fr";

/************************ LOCAL DeV ************************************************/
//export const zUrlBack = "https://localhost:8000"
//export const zAPIdocuments = "https://localhost:8000/consos";
// export const zUrlBackNotifications = "https://localhost:8001";

/**
 * Fin constantes spécifiques alvéole
 */

/************************ Constantes ***********************************************/
export const zURLhome = "https://home-dev.cristal-hub.fr/customer";
export const zURLprofile = "https://profile-dev.cristal-hub.fr";


